module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b3d7a0673928480ea2a8f357f6f58412@o132400.ingest.sentry.io/5559432","environment":"development","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"cross-scale.dogfishmusic.co.uk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ChordFlow","short_name":"ChordFlow","description":"A tool for becoming fluent at playing chords","start_url":"/","scope":"/","background_color":"#1f2d3b","theme_color":"#1f2d3b","display":"standalone","orientation":"portrait","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0ee59caaf42bdea149c9ef195cd05f07"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"],"appendScript":"/tmp/build_27d1fd05/src/custom-sw.js","workboxConfig":{"skipWaiting":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
