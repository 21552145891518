/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapWithProvider from './wrap-with-provider';

export const wrapRootElement = wrapWithProvider;

export const registerServiceWorker = () => true

// export const onServiceWorkerUpdateReady = () => {
//     // Set window.___swUpdated to prevent update on page navigation.
//     // Overrides https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby/cache-dir/navigation.js#L64
//     if (typeof window !== 'undefined') {
//         window.___swUpdated = false;
//     }
// };
